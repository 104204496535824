<template>
  <div class="zone-details-lieux">
    <div class="titre-details-lieu">
      Lieux de stockage utilisés
    </div>
    <div class="liste-lieux-utilises">
      <div
        v-for="lieu of listeLieuxUtilises"
        :key="lieu.id_lieu"
        class="frame-details-lieu-festival"
      >
        <ComposantDetailsLieuFestival
          @afficher-lieu="afficherLieu"
          class="details-lieu-festival"
          :lieu="lieu"
        />
      </div>
    </div>
    <div class="close-cross" @click="cacherDetailsLieuxMobile">
      <div class="btn btn-primary">Retour</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComposantZoneDetailsLieuxFestival",
  components: {
    ComposantDetailsLieuFestival: () =>
      import("./composantDetailsLieuFestival"),
  },
  props: {
    listeLieuxUtilises: { default: [] },
    afficherLieu: {
      type: Function,
      default: () =>
        console.error(
          "ComposantZoneDetailsLieuxFestival : aucune fonction d'affichage de lieu transmise"
        ),
    },
    cacherDetailsLieuxMobile: {
      type: Function,
      default: () =>
        console.error(
          "ComposantZoneDetailsLieuxFestival : aucune fonction de fermeture d'onglet mobile transmise"
        ),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

.zone-details-lieux {
  text-align: center;
  @include typo-festival;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $couleur-texte-clair;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  min-width: 250px;
  max-width: 350px;
  @media (max-width: $mobile-break-point) {
    z-index: 10;
    display: block;
    position: fixed;
    top: 60px;
    margin: auto;
    max-width: none;
    width: 100%;
    height: 90vh;
    padding-bottom: 50px;
  }
}

.titre-details-lieu {
  margin-bottom: $marge-titre;
  font-size: $title-font-size;
}

.liste-lieux-utilises {
}

.frame-details-lieu-festival {
  width: 100%;
  display: flex;
  justify-content: center;
}

.details-lieu-festival {
  margin-bottom: 10px;
  @media (max-width: $mobile-break-point) {
    margin-bottom: 20px;
    width: 350px;
  }
}

.close-cross {
  @include closeCross(200);
  & .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 50%;
    @include typo-large;
  }
}
</style>
